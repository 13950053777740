<template>
  <svg
    viewBox="0 0 14 14"
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.0072 3.15497C12.3309 3.47227 12.3309 3.98672 12.0072 4.30402L5.92826 10.2623C5.60454 10.5797 5.07968 10.5797 4.75595 10.2623L1.99279 7.55402C1.66907 7.23672 1.66907 6.72227 1.99279 6.40497C2.31652 6.08767 2.84138 6.08767 3.1651 6.40497L5.34211 8.53878L10.8349 3.15497C11.1586 2.83767 11.6835 2.83767 12.0072 3.15497Z"
    />
  </svg>
</template>
